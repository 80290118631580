import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import projectsData from '../../json/projects';
import PageHeader from '../../components/PageHeader/PageHeader';
import ProjectText from '../../components/Project/ProjectText';
import ProjectImages from '../../components/Project/ProjectImages';
import BackHome from '../../components/BackHome/BackHome';
import ProjectFooter from '../../components/Project/ProjectFooter';

class BarJewellery extends Component {

    constructor(props) {
        super(props);

        this.project = projectsData.projects.barJewellery;

        this.pageTitle = `${this.project.title} – Jase Warner`;
        this.pageUrl = `https://jase.io/${this.project.slug}`;
        this.pageDescription = 'Shopify consultancy and theme development for jewellery brand';
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>{this.pageTitle}</title>
                    <link rel="canonical" href={this.pageUrl} />
                    <meta name="description" content={this.pageDescription} />
                    <meta property="og:title" content={this.pageTitle} />
                    <meta property="og:description" content={this.pageDescription} />
                    <meta property="og:url" content={this.pageUrl} />
                </Helmet>
                <article>
                    <PageHeader
                        title={this.project.title}
                        tags={this.project.tags}
                        description={this.pageDescription}
                    />

                    <ProjectText
                        text={`<p><a href="https://barjewellery.com/" target="_blank" rel="noopener noreferrer">BAR Jewellery</a> is a London based jewellery brand founded by Sophie Mckay, whose “aesthetic is informed by elegance and simplicity, surpassing the transcience of trends, in the hope that you will wear the pieces for years to come”. Sophie hired me to lend a hand in getting a brand new Shopify store up and running.</p>`}
                    />

                    <ProjectImages
                        firstSrc={`/assets/images/projects/${this.project.cat}/${this.project.slug}/jase-warner-${this.project.slug}-02.jpg`}
                        firstSrc2x={`/assets/images/projects/${this.project.cat}/${this.project.slug}/jase-warner-${this.project.slug}-02@2x.jpg`}
                        secondSrc={`/assets/images/projects/${this.project.cat}/${this.project.slug}/jase-warner-${this.project.slug}-03.jpg`}
                        secondSrc2x={`/assets/images/projects/${this.project.cat}/${this.project.slug}/jase-warner-${this.project.slug}-03@2x.jpg`}
                    />

                    <BackHome/>

                    <ProjectFooter
                        prev={projectsData.projects.toTheInvisible}
                        next={projectsData.projects.dawnChorusInFlorence}
                    />
                </article>
            </>
        )
    }
}

export default BarJewellery;
