import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import PageHeader from '../../components/PageHeader/PageHeader';
import ProjectText from '../../components/Project/ProjectText';
import ProjectImage from '../../components/Project/ProjectImage';
import BackHome from '../../components/BackHome/BackHome';
import ProjectFooter from '../../components/Project/ProjectFooter';
import projectsData from '../../json/projects';

class WDMP extends Component {

	constructor(props) {
		super(props);

		this.project = projectsData.projects.wdmp;

		this.pageTitle = `${this.project.title} – Jase Warner`;
		this.pageUrl = `https://jase.io/${this.project.slug}`;
		this.pageDescription = 'Bespoke WordPress theme development for WDMP';
	}

	render() {
		return (
			<>
				<Helmet>
                    <title>{this.pageTitle}</title>
                    <link rel="canonical" href={this.pageUrl} />
                    <meta name="description" content={this.pageDescription} />
                    <meta name="og:title" content={this.pageTitle} />
                    <meta name="og:description" content={this.pageDescription} />
                    <meta name="og:url" content={this.pageUrl} />
                </Helmet>
				<article>
					<PageHeader
						title={this.project.title}
						tags={this.project.tags}
						description={`Bespoke WordPress theme development`}
					/>

					<ProjectText
						text={`<p><a href="http://www.wdmp.co.uk/" target="_blank" rel="noreferrer noopener">WDMP</a> is a London based marketing agency. They hired me to develop their new website.</p>`}
					/>

					<ProjectImage
						src={`/assets/images/projects/${this.project.cat}/${this.project.slug}/jase-warner-${this.project.slug}-02.jpg`}
						src2x={`/assets/images/projects/${this.project.cat}/${this.project.slug}/jase-warner-${this.project.slug}-02@2x.jpg`}
					/>

					<BackHome/>

					<ProjectFooter
						prev={projectsData.projects.paulBaillieLane}
						next={projectsData.projects.disconnect}
					/>
				</article>
			</>
		)
	}
}

export default WDMP;
