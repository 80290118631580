import React, { Component } from 'react';
import './Nav.scss';
import { NavLink } from 'react-router-dom';
import Social from '../Social/Social';

// data
import globalData from '../../json/global';
import pagesData from '../../json/pages';
import projectsData from '../../json/projects';

class Nav extends Component {

    constructor(props) {
        super(props);

        this.activeClass = 'nav-item--active';
        this.pageLinks = [
            {
                title: "Home",
                url: "",
            },
            {
                title: pagesData.about.title,
                url: pagesData.about.slug,
            }
        ];
        this.webProjectLinks = [
            {
                title: projectsData.projects.seren.title,
                url: projectsData.projects.seren.slug
            },
            {
                title: projectsData.projects.yaitte.title,
                url: projectsData.projects.yaitte.slug
            },
            {
                title: projectsData.projects.rockRaw.title,
                url: projectsData.projects.rockRaw.slug
            },
            {
                title: projectsData.projects.mashu.title,
                url: projectsData.projects.mashu.slug
            },
            {
                title: projectsData.projects.kittyCooper.title,
                url: projectsData.projects.kittyCooper.slug
            }
        ];
        this.soundProjectLinks = [
            {
                title: projectsData.projects.sleepCycleSingle.title,
                url: projectsData.projects.sleepCycleSingle.slug
            },
            {
                title: projectsData.projects.hypnagogia.title,
                url: projectsData.projects.hypnagogia.slug
            },
            {
                title: projectsData.projects.aLW.title,
                url: projectsData.projects.aLW.slug
            },
            {
                title: projectsData.projects.delta.title,
                url: projectsData.projects.delta.slug
            },
            {
                title: projectsData.projects.state.title,
                url: projectsData.projects.state.slug
            }
        ];

    }

    render() {
        return (
            <nav className="nav">
                <div className="nav__content">
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="col-12 col-sm-4 col-lg-3">
                                <ul className="nav__primary" role="menu">

                                    {this.pageLinks.map((link, key) => {
                                        return (
                                            <li key={key} role="menuitem">
                                                <NavLink className="nav-item"
                                                         activeClassName={this.activeClass}
                                                         aria-label={link.title}
                                                         onClick={this.props.toggleNav}
                                                         to={`/${link.url}`}
                                                         exact={link.url === "" ? true : false}>{link.title}</NavLink>
                                            </li>
                                        )
                                    })}

                                    <li role="menuitem">
                                        <button onClick={this.props.handleShowModal} className="nav-item" type="button">{pagesData.contact.title}</button>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-12 col-sm-8 col-lg-6 col-xl-5">
                                <h4>Featured work</h4>
                                <div className="row">
                                    <div className="col-12 col-sm-6">
                                        <h5>{globalData.categories[0].title}</h5>
                                        <ul className="nav__secondary" role="menu">

                                            {this.webProjectLinks.map((link, key) => {
                                                return (
                                                    <li key={key} role="menuitem">
                                                        <NavLink className="nav-item"
                                                                 activeClassName={this.activeClass}
                                                                 aria-label={link.title}
                                                                 onClick={this.props.toggleNav}
                                                                 to={`/${link.url}`}>{link.title}</NavLink>
                                                    </li>
                                                )
                                            })}

                                        </ul>
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <h5>{globalData.categories[1].title}</h5>
                                        <ul className="nav__secondary" role="menu">

                                            {this.soundProjectLinks.map((link, key) => {
                                                return (
                                                    <li key={key} role="menuitem">
                                                        <NavLink className="nav-item"
                                                                 activeClassName={this.activeClass}
                                                                 aria-label={link.title}
                                                                 onClick={this.props.toggleNav}
                                                                 to={`/${link.url}`}>{link.title}</NavLink>
                                                    </li>
                                                )
                                            })}

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Social/>
                </div>
            </nav>
        )
    }
}

export default Nav;
