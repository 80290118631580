import React, {Component} from 'react';
import PageHeader from '../../components/PageHeader/PageHeader';
import projectsData from '../../json/projects';
import ProjectFooter from '../../components/Project/ProjectFooter';
import BackHome from '../../components/BackHome/BackHome';
import { Helmet } from 'react-helmet-async';
import ProjectText from "../../components/Project/ProjectText";
import ProjectImage from "../../components/Project/ProjectImage";

class WPCliInstaller extends Component {

	constructor(props) {
		super(props);

		this.project = projectsData.projects.wordpressInstaller;

		this.pageTitle = `${this.project.title} – Jase Warner`;
		this.pageUrl = `https://jase.io/${this.project.slug}`;
		this.pageDescription = 'A shell script for installing WordPress along with useful plugins and a boilerplate theme packaged with Gulp.js and ES6 support.';
	}

	render() {
		return (
			<>
				<Helmet>
                    <title>{this.pageTitle}</title>
                    <link rel="canonical" href={this.pageUrl} />
                    <meta name="description" content={this.pageDescription} />
                    <meta name="og:title" content={this.pageTitle} />
                    <meta name="og:description" content={this.pageDescription} />
                    <meta name="og:url" content={this.pageUrl} />
                </Helmet>
				<article>
					<PageHeader
						title={this.project.title}
						tags={this.project.tags}
						description="WordPress core and boilerplate theme command-line installer"
					/>

					<ProjectText
						text={`<p>A <a href="https://github.com/jasewarner/wordpress-installer" target="_blank" rel="noopener noreferrer">shell script</a> for installing WordPress along with useful plugins and my <a href="https://github.com/jasewarner/gulp-wordpress" target="_blank" rel="noopener noreferrer">boilerplate theme</a> packaged with Gulp.js and ES6 support.</p>`}
					/>

					<ProjectImage
						src={`/assets/images/projects/${this.project.cat}/${this.project.slug}/jase-warner-${this.project.slug}-02.jpg`}
						src2x={`/assets/images/projects/${this.project.cat}/${this.project.slug}/jase-warner-${this.project.slug}-02@2x.jpg`}
					/>

					<BackHome/>

					<ProjectFooter
						prev={projectsData.projects.hondaCartagena}
						next={projectsData.projects.mexicoColombia}
					/>
				</article>
			</>
		)
	}
}

export default WPCliInstaller;
