import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import PageHeader from '../../components/PageHeader/PageHeader';
import ProjectText from '../../components/Project/ProjectText';
import ProjectImages from '../../components/Project/ProjectImages';
import BackHome from '../../components/BackHome/BackHome';
import ProjectFooter from '../../components/Project/ProjectFooter';
import projectsData from '../../json/projects';

class FatDogSolutions extends Component {

	constructor(props) {
		super(props);

		this.project = projectsData.projects.fatDogSolutions;

		this.pageTitle = `${this.project.title} – Jase Warner`;
		this.pageUrl = `https://jase.io/${this.project.slug}`;
		this.pageDescription = 'Bespoke WordPress theme design and development for studio installation specialists FatDog Solutions';
	}

	render() {
		return (
			<>
				<Helmet>
                    <title>{this.pageTitle}</title>
                    <link rel="canonical" href={this.pageUrl} />
                    <meta name="description" content={this.pageDescription} />
                    <meta name="og:title" content={this.pageTitle} />
                    <meta name="og:description" content={this.pageDescription} />
                    <meta name="og:url" content={this.pageUrl} />
                </Helmet>
				<article>
					<PageHeader
						title={this.project.title}
						tags={this.project.tags}
						description={this.pageDescription}
					/>

					<ProjectText
						text={`<p><a href="http://fatdogsolutions.co.uk" target="_blank" rel="noreferrer noopener">FatDog Solutions</a> are studio installation specialists with “over 30 years experience in both broadcast and corporate video, and over 16 years in TV studios”. They hired me to design and develop a theme for their new WordPress website.</p>`}
					/>

					<ProjectImages
						firstSrc={`/assets/images/projects/${this.project.cat}/${this.project.slug}/jase-warner-${this.project.slug}-02.jpg`}
						firstSrc2x={`/assets/images/projects/${this.project.cat}/${this.project.slug}/jase-warner-${this.project.slug}-02@2x.jpg`}
						secondSrc={`/assets/images/projects/${this.project.cat}/${this.project.slug}/jase-warner-${this.project.slug}-03.jpg`}
						secondSrc2x={`/assets/images/projects/${this.project.cat}/${this.project.slug}/jase-warner-${this.project.slug}-03@2x.jpg`}
					/>

					<BackHome/>

					<ProjectFooter
						prev={projectsData.projects.byPariah}
						next={projectsData.projects.state}
					/>
				</article>
			</>
		)
	}
}

export default FatDogSolutions;
