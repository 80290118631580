import React, {Component} from 'react';
import PageHeader from '../../components/PageHeader/PageHeader';
import projectsData from '../../json/projects';
import ProjectTrack from '../../components/Project/ProjectTrack';
import ProjectText from '../../components/Project/ProjectText';
import ProjectFooter from '../../components/Project/ProjectFooter';
import BackHome from '../../components/BackHome/BackHome';
import { Helmet } from 'react-helmet-async';

class Myanmar extends Component {

	constructor(props) {
		super(props);

		this.project = projectsData.projects.myanmar;

		this.pageTitle = `${this.project.title} – Jase Warner`;
		this.pageUrl = `https://jase.io/${this.project.slug}`;
		this.pageDescription = '“Myanmar” is an industrial soundscape and my contribution to the latest Cities & Memory open call.';
	}

	render() {
		return (
			<>
				<Helmet>
                    <title>{this.pageTitle}</title>
                    <link rel="canonical" href={this.pageUrl} />
                    <meta name="description" content={this.pageDescription} />
                    <meta name="og:title" content={this.pageTitle} />
                    <meta name="og:description" content={this.pageDescription} />
                    <meta name="og:url" content={this.pageUrl} />
                </Helmet>
				<article>
					<PageHeader
						title={this.project.title}
						tags={this.project.tags}
						description="“Myanmar” is an industrial soundscape and my contribution to the latest Cities & Memory open call."
					/>

					<ProjectText
						text={`<p>Cities & Memory’s latest open call asked sound artists to rework field recordings taken from Myanmar by contributor <a href="https://www.kvmr.org/users/tenali-hrenak" target="_blank" rel="noopener noreferrer">Tenali Hrenak</a>. One recording in particular from Mani Sithu Market, where people worked sewing machines amid the general chatter and hubbub, stood out.</p>`}
					/>

					<ProjectTrack
						title="Sewing Machines in Mani Sithu Market by Tenali Hrenak"
						id="394996002"
					/>

					<ProjectText
						text={`<p>My intention was to create a soundscape that portrayed the mechanisms of a machine with a human heart at the centre of it.</p>
							<p>The original recording has been reworked, chopped up, reversed in parts, layered with effects, and welded back together again. I recorded bass guitar to accompany it, along with drums, to complement the rhythmic quality of the sewing machines.</p>
							<p>The final result is an industrial soundscape that aims to preserve the voices of the people working in the market, and the sonance of the tools integral to their trade and livelihood.</p>
							<p>Here’s my soundscape&hellip;</p>`}
					/>

					<ProjectTrack
						title="Myanmar"
						id="394997589"
					/>

					<BackHome/>

					<ProjectFooter
						prev={projectsData.projects.mexicoColombia}
						next={projectsData.projects.fibres}
					/>
				</article>
			</>
		)
	}
}

export default Myanmar;
