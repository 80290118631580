import React, {Component} from 'react';
import PageHeader from '../../components/PageHeader/PageHeader';
import projectsData from '../../json/projects';
import ProjectTrack from '../../components/Project/ProjectTrack';
import ProjectFooter from '../../components/Project/ProjectFooter';
import BackHome from '../../components/BackHome/BackHome';
import { Helmet } from 'react-helmet-async';
import ProjectText from "../../components/Project/ProjectText";

class AMorningInMay extends Component {

	constructor(props) {
		super(props);

		this.project = projectsData.projects.aMorningInMay;

		this.pageTitle = `${this.project.title} – Jase Warner`;
		this.pageUrl = `https://jase.io/${this.project.slug}`;
		this.pageDescription = 'Soundscape for a Cities and Memory project.';
	}

	render() {
		return (
			<>
				<Helmet>
                    <title>{this.pageTitle}</title>
                    <link rel="canonical" href={this.pageUrl} />
                    <meta name="description" content={this.pageDescription} />
                    <meta name="og:title" content={this.pageTitle} />
                    <meta name="og:description" content={this.pageDescription} />
                    <meta name="og:url" content={this.pageUrl} />
                </Helmet>
				<article>
					<PageHeader
						title={this.project.title}
						tags={this.project.tags}
						description="Soundscape for a Cities and Memory project"
					/>

					<ProjectText
						text={`<p>The most notable sounds of the original field recording provided by Stuart, the creator of the Cities and Memory project, feature the performance of a choir as well as the ringing of church bells. I set out to create something that complimented the structure of a choir, by way of combining various parts of the original recording to make a complete sound; hopefully this approach comes across in the finished piece. The organic nature of recycling an original field recording and reforming it to make something new has a certain appeal.</p>`}
					/>

					<ProjectTrack
						title="A Morning in May"
						id="214123454"
					/>

					<BackHome/>

					<ProjectFooter
						prev={projectsData.projects.swimmingWithCicadas}
						next={projectsData.projects.island}
					/>
				</article>
			</>
		)
	}
}

export default AMorningInMay;
