import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import Testimonials from '../Testimonials/Testimonials';
import Footer from '../Footer/Footer';
import comp404 from '../404/404';

// pages
import Home from '../../pages/Home/Home';
import About from '../../pages/About/About';

// web projects
import ClassicalGemHunter from '../../projects/web/ClassicalGemHunter';
import Yaitte from '../../projects/web/Yaitte';
import KittyCooper from '../../projects/web/KittyCooper';
import HooverDirect from '../../projects/web/HooverDirect';
import Noa from '../../projects/web/Noa';
import PayLessForStorage from '../../projects/web/PayLessForStorage';
import BlackBrown from '../../projects/web/BlackBrown';
import RockRaw from '../../projects/web/RockRaw';
import Mashu from '../../projects/web/Mashu';
import Grato from '../../projects/web/Grato';
import Seren from '../../projects/web/Seren';
import Kintana from '../../projects/web/Kintana';
import HannaFiedler from '../../projects/web/HannaFiedler';
import DNAYS from '../../projects/web/DNAYS';
import ToTheInvisible from '../../projects/web/ToTheInvisible';
import BarJewellery from '../../projects/web/BarJewellery';
import Ross from '../../projects/web/Ross';
import ByPariah from '../../projects/web/ByPariah';
import FatDogSolutions from '../../projects/web/FatDogSolutions';
import SouthdownsManor from '../../projects/web/SouthdownsManor';
import Homunculus from '../../projects/web/Homunculus';
import NCFA from '../../projects/web/NCFA';
import BharatJean from '../../projects/web/BharatJean';
import WPCliInstaller from '../../projects/web/WPCliInstaller';
import PaulBaillieLane from '../../projects/web/PaulBaillieLane';
import WDMP from '../../projects/web/WDMP';
import GulpShopify from '../../projects/web/GulpShopify';
import ColourHive from '../../projects/web/ColourHive';
import MaxenceParache from '../../projects/web/MaxenceParache';
import IsabelPradilla from '../../projects/web/IsabelPradilla';
import ThomasHumeau from '../../projects/web/ThomasHumeau';

// sound projects
import Hypnagogia from '../../projects/sound/Hypnagogia';
import SleepCycleSingle from '../../projects/sound/SleepCycleSingle';
import WinterSwimmer from '../../projects/sound/WinterSwimmer';
import ALW from '../../projects/sound/ALW';
import WhenYoureHere from '../../projects/sound/WhenYoureHere';
import Cactus from '../../projects/sound/Cactus';
import Silo from '../../projects/sound/Silo';
import DawnChorusInFlorence from '../../projects/sound/DawnChorusInFlorence';
import Delta from '../../projects/sound/Delta';
import State from '../../projects/sound/State';
import TekN from '../../projects/sound/TekN';
import YellowstoneAnemone from '../../projects/sound/YellowstoneAnemone';
import SouthAfrica from '../../projects/sound/SouthAfrica';
import MorningSong from '../../projects/sound/MorningSong';
import HondaCartagena from '../../projects/sound/HondaCartagena';
import MexicoColombia from '../../projects/sound/MexicoColombia';
import Myanmar from '../../projects/sound/Myanmar';
import Fibres from '../../projects/sound/Fibres';
import LondonsCanals from '../../projects/sound/LondonsCanals';
import Disconnect from '../../projects/sound/Disconnect';
import Nocturne from '../../projects/sound/Nocturne';
import MayHill from '../../projects/sound/MayHill';
import Lines250716 from '../../projects/sound/Lines250716';
import Utopia from '../../projects/sound/Utopia';
import SwimmingWithCicadas from '../../projects/sound/SwimmingWithCicadas';
import AMorningInMay from '../../projects/sound/AMorningInMay';
import Island from '../../projects/sound/Island';
import Trees14 from '../../projects/sound/Trees14';
import SouthernFrance from '../../projects/sound/SouthernFrance';
import Spectrum from '../../projects/sound/Spectrum';
import OmegaCentauri from '../../projects/sound/OmegaCentauri';
import MarieDreaming from '../../projects/sound/MarieDreaming';
import Paris from '../../projects/sound/Paris';
import Avignon from '../../projects/sound/Avignon';
import Venice from '../../projects/sound/Venice';
import Passengers from '../../projects/sound/Passengers';

// data
import pagesData from '../../json/pages';
import projectsData from '../../json/projects';

function Container({ location }) {
	return (
		<>
			<TransitionGroup className="transition-group">
				<CSSTransition
					key={location.key}
					timeout={{ enter: 300, exit: 300 }}
					classNames={'fade'}>
					<section className="route-section">
						<main className="main">
							<Switch location={location}>

								{/*pages*/}
								<Route exact path='/' component={Home} />
								<Route path={`/${pagesData.about.slug}`} component={About} />

								{/*web projects*/}
								<Route path={`/${projectsData.projects.classicalGemHunter.slug}`} component={ClassicalGemHunter} />
								<Route path={`/${projectsData.projects.yaitte.slug}`} component={Yaitte} />
								<Route path={`/${projectsData.projects.kittyCooper.slug}`} component={KittyCooper} />
								<Route path={`/${projectsData.projects.mashu.slug}`} component={Mashu} />
								<Route path={`/${projectsData.projects.noa.slug}`} component={Noa} />
								<Route path={`/${projectsData.projects.payLessForStorage.slug}`} component={PayLessForStorage} />
								<Route path={`/${projectsData.projects.blackBrown.slug}`} component={BlackBrown} />
								<Route path={`/${projectsData.projects.rockRaw.slug}`} component={RockRaw} />
								<Route path={`/${projectsData.projects.hooverDirect.slug}`} component={HooverDirect} />
								<Route path={`/${projectsData.projects.grato.slug}`} component={Grato} />
								<Route path={`/${projectsData.projects.seren.slug}`} component={Seren} />
								<Route path={`/${projectsData.projects.kintana.slug}`} component={Kintana} />
								<Route path={`/${projectsData.projects.hannaFiedler.slug}`} component={HannaFiedler} />
								<Route path={`/${projectsData.projects.dnays.slug}`} component={DNAYS} />
								<Route path={`/${projectsData.projects.toTheInvisible.slug}`} component={ToTheInvisible} />
								<Route path={`/${projectsData.projects.barJewellery.slug}`} component={BarJewellery} />
								<Route path={`/${projectsData.projects.ross.slug}`} component={Ross} />
								<Route path={`/${projectsData.projects.byPariah.slug}`} component={ByPariah} />
								<Route path={`/${projectsData.projects.fatDogSolutions.slug}`} component={FatDogSolutions} />
								<Route path={`/${projectsData.projects.southdownsManor.slug}`} component={SouthdownsManor} />
								<Route path={`/${projectsData.projects.homunculus.slug}`} component={Homunculus} />
								<Route path={`/${projectsData.projects.ncfa.slug}`} component={NCFA} />
								<Route path={`/${projectsData.projects.bharatJean.slug}`} component={BharatJean} />
								<Route path={`/${projectsData.projects.wordpressInstaller.slug}`} component={WPCliInstaller} />
								<Route path={`/${projectsData.projects.paulBaillieLane.slug}`} component={PaulBaillieLane} />
								<Route path={`/${projectsData.projects.wdmp.slug}`} component={WDMP} />
								<Route path={`/${projectsData.projects.gulpShopify.slug}`} component={GulpShopify} />
								<Route path={`/${projectsData.projects.colourHive.slug}`} component={ColourHive} />
								<Route path={`/${projectsData.projects.maxenceParache.slug}`} component={MaxenceParache} />
								<Route path={`/${projectsData.projects.isabelPradilla.slug}`} component={IsabelPradilla} />
								<Route path={`/${projectsData.projects.thomasHumeau.slug}`} component={ThomasHumeau} />

								{/*sound projects*/}
								<Route path={`/${projectsData.projects.hypnagogia.slug}`} component={Hypnagogia} />
								<Route path={`/${projectsData.projects.sleepCycleSingle.slug}`} component={SleepCycleSingle} />
								<Route path={`/${projectsData.projects.winterSwimmer.slug}`} component={WinterSwimmer} />
								<Route path={`/${projectsData.projects.aLW.slug}`} component={ALW} />
								<Route path={`/${projectsData.projects.whenYoureHere.slug}`} component={WhenYoureHere} />
								<Route path={`/${projectsData.projects.cactus.slug}`} component={Cactus} />
								<Route path={`/${projectsData.projects.silo.slug}`} component={Silo} />
								<Route path={`/${projectsData.projects.dawnChorusInFlorence.slug}`} component={DawnChorusInFlorence} />
								<Route path={`/${projectsData.projects.delta.slug}`} component={Delta} />
								<Route path={`/${projectsData.projects.state.slug}`} component={State} />
								<Route path={`/${projectsData.projects.tekN.slug}`} component={TekN} />
								<Route path={`/${projectsData.projects.yellowstoneAnemone.slug}`} component={YellowstoneAnemone} />
								<Route path={`/${projectsData.projects.southAfrica.slug}`} component={SouthAfrica} />
								<Route path={`/${projectsData.projects.morningSong.slug}`} component={MorningSong} />
								<Route path={`/${projectsData.projects.hondaCartagena.slug}`} component={HondaCartagena} />
								<Route path={`/${projectsData.projects.mexicoColombia.slug}`} component={MexicoColombia} />
								<Route path={`/${projectsData.projects.myanmar.slug}`} component={Myanmar} />
								<Route path={`/${projectsData.projects.fibres.slug}`} component={Fibres} />
								<Route path={`/${projectsData.projects.londonsCanals.slug}`} component={LondonsCanals} />
								<Route path={`/${projectsData.projects.disconnect.slug}`} component={Disconnect} />
								<Route path={`/${projectsData.projects.nocturne.slug}`} component={Nocturne} />
								<Route path={`/${projectsData.projects.mayHill.slug}`} component={MayHill} />
								<Route path={`/${projectsData.projects.lines250716.slug}`} component={Lines250716} />
								<Route path={`/${projectsData.projects.utopia.slug}`} component={Utopia} />
								<Route path={`/${projectsData.projects.swimmingWithCicadas.slug}`} component={SwimmingWithCicadas} />
								<Route path={`/${projectsData.projects.aMorningInMay.slug}`} component={AMorningInMay} />
								<Route path={`/${projectsData.projects.island.slug}`} component={Island} />
								<Route path={`/${projectsData.projects.trees14.slug}`} component={Trees14} />
								<Route path={`/${projectsData.projects.southernFrance.slug}`} component={SouthernFrance} />
								<Route path={`/${projectsData.projects.spectrum.slug}`} component={Spectrum} />
								<Route path={`/${projectsData.projects.omegaCentauri.slug}`} component={OmegaCentauri} />
								<Route path={`/${projectsData.projects.marieDreaming.slug}`} component={MarieDreaming} />
								<Route path={`/${projectsData.projects.paris.slug}`} component={Paris} />
								<Route path={`/${projectsData.projects.avignon.slug}`} component={Avignon} />
								<Route path={`/${projectsData.projects.venice.slug}`} component={Venice} />
								<Route path={`/${projectsData.projects.passengers.slug}`} component={Passengers} />
								
								<Route component={comp404} />

							</Switch>
						</main>
						<Testimonials/>
						<Footer/>
					</section>
				</CSSTransition>
			</TransitionGroup>
		</>
	)
}

export default withRouter(Container);
