import React, {Component} from 'react';
import PageHeader from '../../components/PageHeader/PageHeader';
import projectsData from '../../json/projects';
import ProjectTrack from '../../components/Project/ProjectTrack';
import ProjectFooter from '../../components/Project/ProjectFooter';
import BackHome from '../../components/BackHome/BackHome';
import { Helmet } from 'react-helmet-async';
import ProjectText from "../../components/Project/ProjectText";

class Lines250716 extends Component {

	constructor(props) {
		super(props);

		this.project = projectsData.projects.lines250716;

		this.pageTitle = `${this.project.title} – Jase Warner`;
		this.pageUrl = `https://jase.io/${this.project.slug}`;
		this.pageDescription = 'Lines250716 is a soundscape made up of four field recordings taken on Monday 25th July 2016 on the London Underground at separate stations.';
	}

	render() {
		return (
			<>
				<Helmet>
                    <title>{this.pageTitle}</title>
                    <link rel="canonical" href={this.pageUrl} />
                    <meta name="description" content={this.pageDescription} />
                    <meta name="og:title" content={this.pageTitle} />
                    <meta name="og:description" content={this.pageDescription} />
                    <meta name="og:url" content={this.pageUrl} />
                </Helmet>
				<article>
					<PageHeader
						title={this.project.title}
						tags={this.project.tags}
						description="Lines250716 is a soundscape made up of four field recordings taken on Monday 25th July 2016 on the London Underground at separate stations using my Zoom H2n field mic."
					/>

					<ProjectText
						text={`<p>The four field recordings represent where I have lived in London over the past 5 years to the present day. They are arranged as four movements in the soundscape and are in chronological order: Harrow-on-the-Hill, Shepherd’s Bush, Finsbury Park and Caledonian Road.</p>`}
					/>

					<ProjectTrack
						title="Lines250716"
						id="275904237"
					/>

					<ProjectText
						text={`<p>Each movement contains chopped up pieces of the respective field recording. The original recordings document my approach to each station, where I proceed to disembark and make my way to the ticket hall level.</p>
							<p>These chunks of sound represent the mechanics of a machine in transit, while the deconstruction of the field recordings reflects the diffusion of memory.</p>
							<p>The soundscape is a part of the Cities and Memory <a href="http://citiesandmemory.com/thenextstation/" target="_blank" rel="noreferrer noopener">Next Station</a> project.</p>`}
					/>

					<BackHome/>

					<ProjectFooter
						prev={projectsData.projects.gulpShopify}
						next={projectsData.projects.maxenceParache}
					/>
				</article>
			</>
		)
	}
}

export default Lines250716;
