import React, {Component} from 'react';
import PageHeader from '../../components/PageHeader/PageHeader';
import projectsData from '../../json/projects';
import ProjectTrack from '../../components/Project/ProjectTrack';
import ProjectFooter from '../../components/Project/ProjectFooter';
import BackHome from '../../components/BackHome/BackHome';
import { Helmet } from 'react-helmet-async';
import ProjectText from "../../components/Project/ProjectText";

class DawnChorusInFlorence extends Component {

	constructor(props) {
		super(props);

		this.project = projectsData.projects.dawnChorusInFlorence;

		this.pageTitle = `${this.project.title} – Jase Warner`;
		this.pageUrl = `https://jase.io/${this.project.slug}`;
		this.pageDescription = 'Dawn chorus in Florence is a composition for the Cities and Memory #StayHomeSounds project.';
	}

	render() {
		return (
			<>
				<Helmet>
                    <title>{this.pageTitle}</title>
                    <link rel="canonical" href={this.pageUrl} />
                    <meta name="description" content={this.pageDescription} />
                    <meta name="og:title" content={this.pageTitle} />
                    <meta name="og:description" content={this.pageDescription} />
                    <meta name="og:url" content={this.pageUrl} />
                </Helmet>
				<article>
					<PageHeader
						title={this.project.title}
						tags={this.project.tags}
						description={this.pageDescription}
					/>

					<ProjectText
						text={`<p>The piece uses elements of a field recording by <a href="https://soundcloud.com/valerioorlandini" target="_blank" rel="noreferrer noopener">Valerio Orlandini</a>. The recording was taken at dawn in Florence near the borders of the historical city centre during the lockdown, where, in Valerio’s words, “traffic and lots of people” would normally be the dominant soundscape.</p>`}
					/>

					<ProjectTrack
						title="Dawn Chorus in Florence"
						id="826587352"
					/>

					<ProjectText
						text={`<p>After listening to Valerio’s recording, I wanted to play guitar to accompany and complement the birdsong, but do so in an informal and almost improvised manner. I started work on the composition by creating a beat, using snippets of the field recording and then recorded the guitar part, followed by piano. Finally I layered various sections of the field recording throughout the composition using effects to create several brief sonic moments.</p>`}
					/>

					<BackHome/>

					<ProjectFooter
						prev={projectsData.projects.barJewellery}
						next={projectsData.projects.ross}
					/>
				</article>
			</>
		)
	}
}

export default DawnChorusInFlorence;
