import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Formsy from 'formsy-react';
import Input from './Input';
import Textarea from './Textarea';
import './ContactModal.scss';
import Button from '../Button/Button';

// api path for form
const API_PATH = 'https://jase.io/api/contact/index.php';

class ContactModal extends Component {

	constructor(props) {
		super(props);

		this.disableButton = this.disableButton.bind(this);
		this.enableButton = this.enableButton.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleValidSubmit = this.handleValidSubmit.bind(this);
		this.renderContent = this.renderContent.bind(this);

		this.state = {
			canSubmit: false,
			isSubmitting: false,
			hasSent: false
		};

		this._isMounted = false;

	}

	componentDidMount() {
		this._isMounted = true;
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	disableButton() {
		if (this._isMounted) {
			this.setState({ canSubmit: false });
		}
	}

	enableButton() {
		if (this._isMounted) {
			this.setState({ canSubmit: true });
		}
	}

	handleSubmit() {
		if (this._isMounted) {
			this.setState({ isSubmitting: true });
		}
	}

	handleValidSubmit(data) {

		if (this._isMounted) {
			this.setState({ isSubmitting: true });
		}

		fetch(API_PATH, {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(data)
		})
			.then((res) => res.json())
			.then((data) => {
				console.log(data);
				if (this._isMounted) {
					this.setState({ hasSent: true });
				}
			})
			.catch((error) => console.log(error))
			.finally(() => {
				if (this._isMounted) {
					this.setState({ isSubmitting: false });
				}
			});

		if (this._isMounted) {
			setTimeout(() => {
				if (this._isMounted) {
					this.setState({ isSubmitting: false })
				}
			}, 1000);

			const modalTitle = document.querySelector('.modal-title');
			const modalBody = document.querySelector('.modal-body');

			setTimeout(() => {
				if (this._isMounted) {
					modalTitle.innerHTML = 'Lovely stuff!';
					modalBody.innerHTML = '<p>Thank you for your message</p>';
				}
			}, 2000);
		}

	}

	renderContent() {
		if (!this.state.hasSent) {
			return (
				<>
					<p>If you would like to chat about a project and are interested in hiring me,
						<br/>send me a message, or <a href="https://app.yunojuno.com/ref/c/n3ynrh/" target="_blank" rel="noreferrer noopener">book me today</a>.</p>
					<Formsy onValidSubmit={this.handleValidSubmit} onSubmit={this.handleSubmit} onValid={this.enableButton} onInvalid={this.disableButton}>
						<div className="row">
							<div className="col-12 col-lg-6">
								<Input
									name="forename"
									type="text"
									defaultValue=""
									validations={{ isWords: true, maxLength: 20 }}
									validationErrors={{
										isWords: 'Please enter your first name',
										maxLength: '20 characters max'
									}}
									id="formForename"
									label="Forename *"
									required
								/>
							</div>
							<div className="col-12 col-lg-6">
								<Input
									name="surname"
									type="text"
									validations={{ isWords: true, maxLength: 20 }}
									validationErrors={{ isWords: 'Please enter your last name', maxLength: '20 characters max' }}
									id="formSurname"
									label="Surname *"
									maxLength="20"
									required
								/>
							</div>
							<div className="col-12 col-lg-6">
								<Input
									name="email"
									type="email"
									validations={{ isEmail: true, maxLength: 50 }}
									validationErrors={{ isEmail: 'Please enter a valid email address', maxLength: '50 characters max' }}
									id="formEmail"
									label="Email address *"
									maxLength="30"
									required
								/>
							</div>
							<div className="col-12 col-lg-6">
								<Input
									name="phone"
									type="tel"
									validations={{ matchRegexp: /^[- +()]*[0-9][- +()0-9]*$/, maxLength: 25 }}
									validationErrors={{ matchRegexp: 'Please enter a valid telephone number', maxLength: '25 characters max' }}
									id="formPhone"
									label="Phone number"
								/>
							</div>
							<div className="col-12">
								<Input
									name="website"
									type="text"
									validations={{ maxLength: 50 }}
									validationsErrors={{ maxLength: '50 characters max' }}
									id="formWebsite"
									label="Your website"
								/>
							</div>
							<div className="col-12">
								<Input
									name="subject"
									type="text"
									validations={{ isExisty: true, maxLength: 30 }}
									validationErrors={{ isExisty: 'Please enter a subject', maxLength: '30 characters max' }}
									id="formSubject"
									label="Message subject *"
									required
								/>
							</div>
							<div className="col-12">
									<Textarea
										name="message"
										validations="isExisty"
										validationError="Please enter your message."
										id="formMessage"
										label="Your message *"
										rows="4"
										required
									/>
							</div>
							<div className="col-12">
								<span className="required-msg">{!this.state.canSubmit ? 'Please fill in all * required fields' : ''}</span>
								<Button type="submit" disabled={!this.state.canSubmit} className={`btn btn--fancy ${this.state.isSubmitting ? 'btn--active' : ''}`} label="Send"/>
							</div>
						</div>
					</Formsy>
				</>
			)
		} else {
			return (
				<>
					<p>Thank you for your message!</p>
				</>
			)
		}
	}

	render() {
		return (
			<>
				<Modal show={this.props.show} onHide={this.props.handleCloseModal} size="lg" centered>
					<Modal.Header closeButton>
						<Modal.Title>Hello!</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{this.renderContent()}
					</Modal.Body>
				</Modal>
			</>
		)
	}
}

export default ContactModal;
