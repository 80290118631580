import React, {Component} from 'react';
import PageHeader from '../../components/PageHeader/PageHeader';
import projectsData from '../../json/projects';
import SpotifyEmbed from '../../components/Project/SpotifyEmbed';
import AppleMusicEmbed from '../../components/Project/AppleMusicEmbed';
import ProjectFooter from '../../components/Project/ProjectFooter';
import BackHome from '../../components/BackHome/BackHome';
import { Helmet } from 'react-helmet-async';
import ProjectImage from "../../components/Project/ProjectImage";

class SleepCycleSingle extends Component {

	constructor(props) {
		super(props);

		this.project = projectsData.projects.sleepCycleSingle;

		this.pageTitle = `${this.project.title} – Jase Warner`;
		this.pageUrl = `https://jase.io/${this.project.slug}`;
		this.pageDescription = 'Single featuring the B-side "Winter Swimmer". Available on Spotify and Apple Music.';
	}

	render() {
		return (
			<>
				<Helmet>
                    <title>{this.pageTitle}</title>
                    <link rel="canonical" href={this.pageUrl} />
                    <meta name="description" content={this.pageDescription} />
                    <meta name="og:title" content={this.pageTitle} />
                    <meta name="og:description" content={this.pageDescription} />
                    <meta name="og:url" content={this.pageUrl} />
                </Helmet>
				<article>
					<PageHeader
						title={this.project.title}
						tags={this.project.tags}
						description={`Single featuring the B-side "Winter Swimmer". <br/>Available on Spotify and Apple Music.`}
					/>

					<SpotifyEmbed
						src="https://open.spotify.com/embed/album/5cKIWezFGE3RjShH174QoH?utm_source=generator&theme=0"
						title="Sleep Cycle single"
					/>

					<ProjectImage
						src={`/assets/images/projects/${this.project.cat}/${this.project.slug}/jase-warner-${this.project.slug}-02.jpg`}
						src2x={`/assets/images/projects/${this.project.cat}/${this.project.slug}/jase-warner-${this.project.slug}-02@2x.jpg`}
					/>

					<AppleMusicEmbed
						src="https://embed.music.apple.com/gb/album/sleep-cycle-single/1663604535"
						title="Sleep Cycle single"
					/>

					<BackHome/>

					<ProjectFooter
						prev={projectsData.projects.blackBrown}
						next={projectsData.projects.rockRaw}
					/>
				</article>
			</>
		)
	}
}

export default SleepCycleSingle;
