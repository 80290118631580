import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import PageHeader from '../../components/PageHeader/PageHeader';
import ProjectText from '../../components/Project/ProjectText';
import ProjectImages from '../../components/Project/ProjectImages';
import BackHome from '../../components/BackHome/BackHome';
import ProjectFooter from '../../components/Project/ProjectFooter';
import projectsData from '../../json/projects';

class Ross extends Component {

	constructor(props) {
		super(props);

		this.project = projectsData.projects.ross;

		this.pageTitle = `${this.project.title} – Jase Warner`;
		this.pageUrl = `https://jase.io/${this.project.slug}`;
		this.pageDescription = 'Bespoke WordPress theme design and development for global brand development consultancy';
	}

	render() {
		return (
			<>
				<Helmet>
                    <title>{this.pageTitle}</title>
                    <link rel="canonical" href={this.pageUrl} />
                    <meta name="description" content={this.pageDescription} />
                    <meta name="og:title" content={this.pageTitle} />
                    <meta name="og:description" content={this.pageDescription} />
                    <meta name="og:url" content={this.pageUrl} />
                </Helmet>
				<article>
					<PageHeader
						title={this.project.title}
						tags={this.project.tags}
						description={this.pageDescription}
					/>

					<ProjectText
						text={`<p><a href="https://paulsross.com/" target="_blank" rel="noreferrer noopener">ROSS</a> is a global brand development consultancy, founded by Paul Ross, driving sustainable growth using a new model: collaboration with a network of world-class partners. Paul, with over 20 years in advertising and a former marketing executive for Inniskillin Wines, is a virtual winemaker and freelance writer who publishes extensively on the wine industry. He hired me to design and develop his new WordPress website.</p>`}
					/>

					<ProjectImages
						firstSrc={`/assets/images/projects/${this.project.cat}/${this.project.slug}/jase-warner-${this.project.slug}-02.jpg`}
						firstSrc2x={`/assets/images/projects/${this.project.cat}/${this.project.slug}/jase-warner-${this.project.slug}-02@2x.jpg`}
						secondSrc={`/assets/images/projects/${this.project.cat}/${this.project.slug}/jase-warner-${this.project.slug}-03.jpg`}
						secondSrc2x={`/assets/images/projects/${this.project.cat}/${this.project.slug}/jase-warner-${this.project.slug}-03@2x.jpg`}
					/>

					<BackHome/>

					<ProjectFooter
						prev={projectsData.projects.dawnChorusInFlorence}
						next={projectsData.projects.delta}
					/>
				</article>
			</>
		)
	}
}

export default Ross;
