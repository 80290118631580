import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import PageHeader from '../../components/PageHeader/PageHeader';
import ProjectText from '../../components/Project/ProjectText';
import ProjectImage from '../../components/Project/ProjectImage';
import BackHome from '../../components/BackHome/BackHome';
import ProjectFooter from '../../components/Project/ProjectFooter';
import projectsData from '../../json/projects';

class GulpShopify extends Component {

    constructor(props) {
        super(props);

        this.project = projectsData.projects.gulpShopify;

        this.pageTitle = `${this.project.title} – Jase Warner`;
        this.pageUrl = `https://jase.io/${this.project.slug}`;
        this.pageDescription = 'Gulp + Shopify is for Developers wishing to use Gulp.js and Shopify’s Theme Kit to develop their Shopify theme – a tidy solution to the problem with Shopify not allowing sub-directories within the assets directory.';
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>{this.pageTitle}</title>
                    <link rel="canonical" href={this.pageUrl} />
                    <meta name="description" content={this.pageDescription} />
                    <meta property="og:title" content={this.pageTitle} />
                    <meta property="og:description" content={this.pageDescription} />
                    <meta property="og:url" content={this.pageUrl} />
                </Helmet>
                <article>
                    <PageHeader
                        title={this.project.title}
                        tags={this.project.tags}
                        description={`Shopify Theme Kit and Gulp.js boilerplate`}
                    />

                    <ProjectText
                        text={`<p><a href="https://github.com/jasewarner/gulp-shopify" target="_blank" rel="noopener noreferrer">Gulp + Shopify</a> is for developers wishing to use Gulp.js in combination with Shopify’s Theme Kit to develop their Shopify theme – a tidy solution to the problem with Shopify not allowing sub-directories within the assets folder.</p>`}
                    />

                    <ProjectImage
                        src={`/assets/images/projects/${this.project.cat}/${this.project.slug}/jase-warner-${this.project.slug}-02.jpg`}
                        src2x={`/assets/images/projects/${this.project.cat}/${this.project.slug}/jase-warner-${this.project.slug}-02@2x.jpg`}
                    />

                    <BackHome/>

                    <ProjectFooter
                        prev={projectsData.projects.mayHill}
                        next={projectsData.projects.lines250716}
                    />
                </article>
            </>
        )
    }
}

export default GulpShopify;
