import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import projectsData from '../../json/projects';
import PageHeader from '../../components/PageHeader/PageHeader';
import ProjectText from '../../components/Project/ProjectText';
import ProjectImages from '../../components/Project/ProjectImages';
import BackHome from '../../components/BackHome/BackHome';
import ProjectFooter from '../../components/Project/ProjectFooter';

class Seren extends Component {

    constructor(props) {
        super(props);

        this.project = projectsData.projects.seren;

        this.pageTitle = `${this.project.title} – Jase Warner`;
        this.pageUrl = `https://jase.io/${this.project.slug}`;
        this.pageDescription = 'Shopify theme design, development, and consultancy for slow and contemporary fashion label';
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>{this.pageTitle}</title>
                    <link rel="canonical" href={this.pageUrl} />
                    <meta name="description" content={this.pageDescription} />
                    <meta property="og:title" content={this.pageTitle} />
                    <meta property="og:description" content={this.pageDescription} />
                    <meta property="og:url" content={this.pageUrl} />
                </Helmet>
                <article>
                    <PageHeader
                        title={this.project.title}
                        tags={this.project.tags}
                        description={this.pageDescription}
                    />

                    <ProjectText
                        text={`<p><a href="https://seren-london.com/" target="_blank" rel="noopener noreferrer">Seren</a> is a “is a slow fashion and modern bridal brand, creating beautiful, limited edition or custom pieces with a focus on timeless elegance and a nostalgia for simpler times”. Lucia, Seren’s founder, hired me to design and develop a bespoke Shopify theme to give her store a contemporary look.</p>`}
                    />

                    <ProjectImages
                        firstSrc={`/assets/images/projects/${this.project.cat}/${this.project.slug}/jase-warner-${this.project.slug}-02.jpg`}
                        firstSrc2x={`/assets/images/projects/${this.project.cat}/${this.project.slug}/jase-warner-${this.project.slug}-02@2x.jpg`}
                        secondSrc={`/assets/images/projects/${this.project.cat}/${this.project.slug}/jase-warner-${this.project.slug}-03.jpg`}
                        secondSrc2x={`/assets/images/projects/${this.project.cat}/${this.project.slug}/jase-warner-${this.project.slug}-03@2x.jpg`}
                    />

                    <BackHome/>

                    <ProjectFooter
                        prev={projectsData.projects.whenYoureHere}
                        next={projectsData.projects.kintana}
                    />
                </article>
            </>
        )
    }
}

export default Seren;
