import React, {Component} from 'react';
import PageHeader from '../../components/PageHeader/PageHeader';
import projectsData from '../../json/projects';
import ProjectTrack from '../../components/Project/ProjectTrack';
import ProjectFooter from '../../components/Project/ProjectFooter';
import BackHome from '../../components/BackHome/BackHome';
import { Helmet } from 'react-helmet-async';
import ProjectText from "../../components/Project/ProjectText";

class SouthernFrance extends Component {

	constructor(props) {
		super(props);

		this.project = projectsData.projects.southernFrance;

		this.pageTitle = `${this.project.title} – Jase Warner`;
		this.pageUrl = `https://jase.io/${this.project.slug}`;
		this.pageDescription = 'Field recordings taken during my trip to the south of France in the summer of 2014.';
	}

	render() {
		return (
			<>
				<Helmet>
                    <title>{this.pageTitle}</title>
                    <link rel="canonical" href={this.pageUrl} />
                    <meta name="description" content={this.pageDescription} />
                    <meta name="og:title" content={this.pageTitle} />
                    <meta name="og:description" content={this.pageDescription} />
                    <meta name="og:url" content={this.pageUrl} />
                </Helmet>
				<article>
					<PageHeader
						title={this.project.title}
						tags={this.project.tags}
						description="Field recordings taken during my trip to the south of France in the summer of 2014"
					/>

					<ProjectTrack
						title="Southern France Field Recordings"
						id="45112428"
						playlist
					/>

					<ProjectText
						text={`<p>The first leg of my trip was spent at a vineyard in Domazan, a commune/parish that is located within a few miles from Avignon. It was here that I spent my previous summer, listening to and recording the buzzing, and yet tranquil, countryside soundscape; upon this visit I was once again compelled as an audience member in my three-day stay, as the ever-rhythmic performances of the cicadas and crickets played out among the grapevines, trees and hedgerows.</p>`}
					/>

					<BackHome/>

					<ProjectFooter
						prev={projectsData.projects.trees14}
						next={projectsData.projects.spectrum}
					/>
				</article>
			</>
		)
	}
}

export default SouthernFrance;
