import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import projectsData from '../../json/projects';
import PageHeader from '../../components/PageHeader/PageHeader';
import ProjectText from '../../components/Project/ProjectText';
import ProjectImages from '../../components/Project/ProjectImages';
import BackHome from '../../components/BackHome/BackHome';
import ProjectFooter from '../../components/Project/ProjectFooter';

class Homunculus extends Component {

    constructor(props) {
        super(props);

        this.project = projectsData.projects.homunculus;

        this.pageTitle = `${this.project.title} – Jase Warner`;
        this.pageUrl = `https://jase.io/${this.project.slug}`;
        this.pageDescription = 'Bespoke WordPress theme design and development for The Homunculus';
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>{this.pageTitle}</title>
                    <link rel="canonical" href={this.pageUrl} />
                    <meta name="description" content={this.pageDescription} />
                    <meta property="og:title" content={this.pageTitle} />
                    <meta property="og:description" content={this.pageDescription} />
                    <meta property="og:url" content={this.pageUrl} />
                </Helmet>
                <article>
                    <PageHeader
                        title={this.project.title}
                        tags={this.project.tags}
                        description={`Bespoke WordPress theme design and development`}
                    />

                    <ProjectText
                        text={`<p><a href="https://thehomunculus.co.uk/" target="_blank" rel="noopener noreferrer">The Homunculus</a> are a London based improvised comedy and film-making duo. Tim and Shaun hired me to illustrate two characters for their identity and design and develop a WordPress theme for the Homunculus website.</p>`}
                    />

                    <ProjectImages
                        firstSrc={`/assets/images/projects/${this.project.cat}/${this.project.slug}/jase-warner-${this.project.slug}-02.jpg`}
                        firstSrc2x={`/assets/images/projects/${this.project.cat}/${this.project.slug}/jase-warner-${this.project.slug}-02@2x.jpg`}
                        secondSrc={`/assets/images/projects/${this.project.cat}/${this.project.slug}/jase-warner-${this.project.slug}-03.jpg`}
                        secondSrc2x={`/assets/images/projects/${this.project.cat}/${this.project.slug}/jase-warner-${this.project.slug}-03@2x.jpg`}
                    />

                    <BackHome/>

                    <ProjectFooter
                        prev={projectsData.projects.southdownsManor}
                        next={projectsData.projects.ncfa}
                    />
                </article>
            </>
        )
    }
}

export default Homunculus;
