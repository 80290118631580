import React, { Component } from 'react';
import './Intro.scss';
import Social from '../Social/Social';
import Two from 'two.js/build/two';

class Intro extends Component {

	constructor(props) {
		super(props);

		this.two = null;
	}

	componentDidMount() {

		const introShapes = document.getElementById('introShapes');

		// Initialise an instance to render
		const two = new Two({
			type: Two.Types.svg,
			fullscreen: false,
			width: window.innerWidth,
			height: window.innerHeight,
			autostart: true
		}).appendTo(introShapes);

		this.two = two;

		// List of colours for the linear gradient to cycle through
		const colors = [
			'rgba(23, 29, 54, 1)', // $dark
			'rgba(15, 51, 53, 1)', // $dark-blue-grey
			'rgba(8, 50, 94, 1)', // $marine
			'rgba(26, 19, 14, 1)', // $black
			'rgba(26, 88, 91, 1)', // $dark-green-blue
			'rgba(15, 68, 123, 1)', // $twilight-blue
		];

		// Define the index of the current colour the gradient is starting from
		let colorIndex = 0;

		// Cache the center of the canvas for positioning objects
		const cx = two.width / 2;
		const cy = two.height / 2;

		// 4 component values use to create a vertical line
		let x1 = 0;
		let y1 = - cy;
		const x2 = 0;
		const y2 = cy;

		// Create a linear gradient with ramp
		// points starting at (x1, y1) and ending at (x2, y2)
		// with three stops evenly spread across the gradient
		const linearGradient = two.makeLinearGradient(x1, y1, x2, y2,
			new Two.Stop(0, colors[0]),
			new Two.Stop(1, colors[1]),
			new Two.Stop(1, colors[2])
		);
		linearGradient.units = 'userSpaceOnUse';

		// Gradients are effects and can be applied
		// to either the `fill` or the `stroke` of
		// a Two.Path or other object.
		// Make a rectangle to be filled by the gradient
		const rectangle = two.makeRectangle(cx, cy, two.width, two.height);
		rectangle.noStroke();
		rectangle.fill = linearGradient;

		// 3 component values used to create a circle.
		x1 = 0;
		y1 = 0;

		// Get the higher value of the two to make
		// a gradient that fills the window
		let radius = Math.max(two.width, two.height);

		// Create a radial gradient with center `x1`, `y1`
		// and radius `radius` with two stops along the ramp
		const radialGradient = two.makeRadialGradient(
			x1,
			y1,
			radius,
			new Two.Stop(0, 'rgba(205, 66, 23, 0.8', 0.6),
			new Two.Stop(0.5, 'rgba(205, 66, 23, 0', 0)
		);
		radialGradient.units = 'userSpaceOnUse';

		// `vignette` is the bounding rectangle
		// to be filled with the radial gradient
		const vignette = two.makeRectangle(cx, cy, two.width, two.height);
		vignette.noStroke();
		vignette.fill = radialGradient;

		// Two temporary vector variables are needed
		// during the animation loop. First is to keep track
		// of the mouse position.
		const mouse = new Two.Vector(cx, cy);

		// Second is an empty value that is set every frame
		// to calculate the distance between the center of the screen and the mouse
		const destination = new Two.Vector();

		// Native HTML event handler to listen when the mouse
		// or finger moves and store its position
		two.renderer.domElement.addEventListener('pointermove', pointermove, false);

		// Two.js event handlers to list when the page resizes
		// and the animation updates
		two.bind('resize', resize).bind('update', update);
		window.addEventListener('resize', resize, false);

		// Set the `mouse` variable to the cursor's position
		function pointermove(e) {
			mouse.set(e.clientX, e.clientY);
		}

		// For both the rectangle and vignette to be the size of the canvas
		// when the window changes size
		function resize() {

			two.width = window.innerWidth;
			two.height = window.innerHeight;

			const cx = two.width / 2;
			const cy = two.height / 2;

			const linearGradient = rectangle.fill;
			linearGradient.left.y = - cy;
			linearGradient.right.y = cy;

			// Set the size of the rectangle
			// to the size of the canvas
			rectangle.width = two.width;
			rectangle.height = two.height;
			vignette.width = two.width;
			vignette.height = two.height;

			// Center both rectangles
			rectangle.translation.set(cx, cy);
			vignette.translation.copy(rectangle.translation);

			// Update the radius variable to accurately
			// reflect the size during the `update` handler
			radius = Math.max(two.width, two.height);
		}

		function update(frameCount) {
			const radialGradient = vignette.fill;
			const cx = two.width / 2;
			const cy = two.height / 2;

			const oscillation = (Math.sin(frameCount / 60) + 1);
			const r25 = (radius / 4);
			const r75 = radius * 0.75;

			// Make the radial gradient oscillate
			radialGradient.radius = r25 * oscillation + r75;

			const destX = mouse.x - cx;
			const destY = mouse.y - cy;

			destination.set(destX, destY)
				.sub(radialGradient.center)
				.multiplyScalar(0.125); // Used to `ease` into dest

			// Move the gradient's center towards the mouse
			// as a kind of "look at" behaviour
			radialGradient.center.add(destination);

			const linearGradient = rectangle.fill;
			const o = linearGradient.stops[1].offset;

			if (o < 0.001) {
				// Make the second stop clamp
				// to the bottom edge
				linearGradient.stops[1].offset = 1;

				// Select a new colour from the palette
				colorIndex = (colorIndex + 1) % colors.length;

				for (let i = 0; i < linearGradient.stops.length; i++) {
					const stop = linearGradient.stops[i];
					const index = colorIndex + i;
					// Set the new colours
					stop.color = colors[index % colors.length];
				}
			} else {
				// Animate the second stop
				// towards the top of the screen
				linearGradient.stops[1].offset -= o * 0.02;
			}
		}

	}

	componentWillUnmount() {
		this.two.pause();
	}

	render() {
		return (
			<div className="intro">
				<div className="intro__shapes" id="introShapes"></div>
				<article className="intro__text">
					<div className="container-fluid">
						<div className="row justify-content-center">
							<div className="col-12">
								<div className="intro__content">
									<h1 className="p">My name is Jase Warner.
										<br/> I’m a freelance web developer <span className="break-xs"></span>based in London.</h1>
									<p>I specialise in developing and designing bespoke Shopify and WordPress themes. In 2023, I was thrilled to win the YunoJuno freelancer of the year award for web development.</p>
									<p>When I’m not writing code and designing, I make ambient and electronic music
										and watch John Carpenter films.</p>
									<p>Scroll down to see some of my work&hellip;</p>
								</div>
							</div>
						</div>
					</div>
				</article>
				<aside className="intro__footer">
					<div className="container-fluid">
						<div className="row justify-content-between">
							<div className="col-auto">
								<Social/>
							</div>
						</div>
					</div>
				</aside>
				<div className="intro__lines">
					<span></span>
					<span></span>
					<span></span>
					<span></span>
					<span></span>
					<span></span>
				</div>
			</div>
		)
	}
}

export default Intro;
