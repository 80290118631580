import React, { Component } from 'react';
import './Clients.scss';

class Clients extends Component {

	constructor(props) {
		super(props);

		this.clientsArr = [
			{
				name: "Ogilvy & Mather", logo: "ogilvy"
			},
			{
				name: "Anissa Kermiche", logo: "anissa-kermiche"
			},
			{
				name: "SAP Fioneer", logo: "sap-fioneer"
			},
			{
				name: "Seren", logo: "seren"
			},
			{
				name: "Mashu", logo: "mashu"
			},
			{
				name: "Ticket Tailor", logo: "ticket-tailor"
			},
			{
				name: "Futsol", logo: "futsol"
			},
			{
				name: "Pay Less for Storage", logo: "pay-less-for-storage"
			},
			{
				name: "Sectorlight", logo: "sectorlight"
			},
			{
				name: "King’s College London", logo: "kcl"
			},
			{
				name: "Bar Jewellery", logo: "bar-jewellery"
			},
			{
				name: "Bharat&Jean", logo: "bharat-jean"
			},
			{
				name: "Kintana", logo: "kintana"
			},
			{
				name: "Mediablaze", logo: "mediablaze"
			},
			{
				name: "Y Wilson", logo: "y-wilson"
			},
			{
				name: "Dewynters", logo: "dewynters"
			},
			{
				name: "Lantum", logo: "lantum"
			},
			{
				name: "Velocity", logo: "velocity"
			},
			{
				name: "Momentum ABM", logo: "momentum"
			},
			{
				name: "WDMP", logo: "wdmp"
			},
			{
				name: "Brand & Deliver", logo: "brand-deliver"
			},
			{
				name: "Merlin Entertainments", logo: "merlin"
			},
			{
				name: "WePixel", logo: "wepixel"
			},
			{
				name: "BMB Agency", logo: "bmb"
			},
			{
				name: "Gyro", logo: "gyro"
			},
			{
				name: "Wildish & Co.", logo: "wildish-co"
			},
			{
				name: "DNAYS", logo: "dnays"
			},
			{
				name: "Adjust Your Set", logo: "adjust-your-set"
			},
			{
				name: "Oakley Moore", logo: "oakley-moore"
			},
			{
				name: "Global Canopy", logo: "global-canopy"
			},
			{
				name: "Oasis", logo: "oasis"
			},
			{
				name: "Isabel Pradilla", logo: "isabel-pradilla"
			},
			{
				name: "Tesco", logo: "tesco"
			}
		]
	}

	render() {
		return (
			<div className="clients">
				<div className="container-fluid">
					<div className="row justify-content-center">
						<div className="col-12 col-sm-10 col-lg-8">
							<h2 className="clients__title h3">{this.props.title}</h2>
							<ul className="clients__list">
								{this.clientsArr.map((client) => {
									return (
										<li key={client.name}>
											<img className="img-fluid"
											     alt={client.name}
											     width="330"
											     height="100"
											     src={`/assets/images/clients/${client.logo}.svg`}/>
										</li>
									)
								})}
							</ul>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default Clients;
