import React, {Component} from 'react';
import HTMLReactParser from "html-react-parser";
import './Project.scss';
import './ProjectText.scss';

class ProjectText extends Component {
	render() {
		return (
			<div className="project-text project-content">
				<div className="container-fluid">
					<div className="row justify-content-center">
						<div className="col-12 col-md-8 col-xl-6">
							{HTMLReactParser(this.props.text)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default ProjectText;
