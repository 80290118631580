import React, {Component} from 'react';
import PageHeader from '../../components/PageHeader/PageHeader';
import projectsData from '../../json/projects';
import ProjectTrack from '../../components/Project/ProjectTrack';
import ProjectFooter from '../../components/Project/ProjectFooter';
import BackHome from '../../components/BackHome/BackHome';
import { Helmet } from 'react-helmet-async';
import ProjectText from '../../components/Project/ProjectText';

class LondonsCanals extends Component {

	constructor(props) {
		super(props);

		this.project = projectsData.projects.londonsCanals;

		this.pageTitle = `${this.project.title} – Jase Warner`;
		this.pageUrl = `https://jase.io/${this.project.slug}`;
		this.pageDescription = 'Ongoing field recording project to capture the soundscapes of London’s canal network.';
	}

	render() {
		return (
			<>
				<Helmet>
                    <title>{this.pageTitle}</title>
                    <link rel="canonical" href={this.pageUrl} />
                    <meta name="description" content={this.pageDescription} />
                    <meta name="og:title" content={this.pageTitle} />
                    <meta name="og:description" content={this.pageDescription} />
                    <meta name="og:url" content={this.pageUrl} />
                </Helmet>
				<article>
					<PageHeader
						title={this.project.title}
						tags={this.project.tags}
						description="Ongoing field recording project to capture the soundscapes of London’s canal network"
					/>

					<ProjectText
						text={`<p>For some time now I have wanted to explore the sounds of the canal network in London and the ever changing soundscapes that surround these placid waters. Once vital to the prosperity of the city and its people, these former veins of industry have since taken on a much different role to the public.</p>`}
					/>

					<ProjectTrack
						title="London’s Canals"
						id="415075079"
						playlist
					/>

					<BackHome/>

					<ProjectFooter
						prev={projectsData.projects.fibres}
						next={projectsData.projects.paulBaillieLane}
					/>
				</article>
			</>
		)
	}
}

export default LondonsCanals;
