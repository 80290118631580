import React, { Component } from 'react';
import { withFormsy } from 'formsy-react';

class Input extends Component {

	constructor(props) {
		super(props);
		this.changeValue = this.changeValue.bind(this);
	}

	changeValue(event) {
		this.props.setValue(event.currentTarget.value);
	}

	render() {
		// an error message is returned only if the component is invalid
		const errorMessage = this.props.getErrorMessage ? this.props.getErrorMessage() : null;

		return (
			<div className="form-group">
				<label htmlFor={this.props.id}>{this.props.label}</label>
				<input
					onChange={this.changeValue}
					type={this.props.type}
					value={this.props.value || ''}
					className="form-control"
					id={this.props.id}
					placeholder={this.props.placeholder}
				/>
		        <span className="error">{errorMessage}</span>
			</div>
		)
	}
}

export default withFormsy(Input);
