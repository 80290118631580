import React, { Component } from 'react';
import './PageText.scss';
import HTMLReactParser from "html-react-parser";

class PageText extends Component {
	render() {
		return (
			<div className="page-text">
				<div className="container-fluid">
					<div className="row justify-content-center">
						<div className="col-12 col-sm-10 col-lg-8">
							<div className="page-text__wrap">
								{HTMLReactParser(this.props.text)}
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default PageText;
